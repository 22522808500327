import {
  TEST,
  LOADED,
  LOADING,
  LOGIN,
  Err,
  notErr,
  OPEN_AUTH_MODAL,
  FIREBASELOGIN,
  LOG_OUT
} from '../actions/action';

const inititalState = {
  data: [],
  authenticated: 0,
  is_loading: false,
  isOpenAuthModal: false,
  currentModal: null,
  errors: {
    status: 0,
    msg: '',
  },
};

export default function giveAwayReducer(state = inititalState, action) {
  switch (action.type) {
    case TEST: {
      let newstate = { ...state, data: action.data };
      return newstate;
    }
    case OPEN_AUTH_MODAL:
      return {
        ...state,
        isOpenAuthModal: action.payload.open,
        currentModal: action.payload.type,
      };
    case LOADING: {
      let newState = { ...state, is_loading: action.data };
      return newState;
    }
    case FIREBASELOGIN: {
      let newState = { ...state, authenticated: 1, token: action.data.token, user: action.data.currentUser};
      return newState;
    }
    case LOADED: {
      let newState = { ...state, is_loading: action.data };
      return newState;
    }
    case LOGIN: {
      let newState = { ...state, authenticated: action.data.status, token: action.data.token };
      return newState;
    }
    case LOG_OUT: {
      return inititalState;
    }

    case Err: {
      let newState = { ...state, errors: action.data };
      return newState;
    }
    case notErr: {
      let newState = { ...state, errors: { msg: '' } };
      return newState;
    }
    default:
      return state;
  }
}
