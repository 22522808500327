import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyBI6rxjC1Vks2mPyUT9DJF9smAQwt2pSyo",
  authDomain: "giveaway-70c8f.firebaseapp.com",
  projectId: "giveaway-70c8f",
  storageBucket: "giveaway-70c8f.appspot.com",
  messagingSenderId: "448916105061",
  appId: "1:448916105061:web:432cf7cf1d53713cba638c",
  measurementId: "G-PHC9YPPD5M"
  // databaseURL: 'https://debater-434f8.firebaseio.com',
};

firebase.initializeApp(config);
firebase.analytics();
export const auth = firebase.auth;
export const googleProvider = new auth.GoogleAuthProvider();
export const db = firebase.database();
