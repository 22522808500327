import { createStore, applyMiddleware, compose } from 'redux';
import giveAwayReducer from './reducers/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

let Compose = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, giveAwayReducer);

if (process.env.NODE_ENV === 'production') {
  Compose = compose(applyMiddleware(thunk));
}

const store = createStore(persistedReducer, Compose);
const persistor = persistStore(store);
export {
    store,
    persistor
};
