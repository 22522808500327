import React from 'react';
import './HomePage.css'; 

const HomePage = props => {
    return (
        <div className="center-dummy">
            <p className="center-flex">
            Something good is coming !
            </p>
            <p className="center-flex">
            Stay tuned !
            </p>
            
        </div>
    )
} 

export default HomePage;
