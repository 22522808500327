import { auth } from "../../services/helper-firebase";

export function signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    return auth().signInWithPopup(provider);
}

export function signinWithEmail(email, password) {
    return auth().signInWithEmailAndPassword(email, password)
}
