import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Header from './components/header/header';
import HomePage from './pages/home/HomePage';
import { connect } from 'react-redux';
import AddListingPage from './pages/listings/AddListingPage';
import mixpanel from 'mixpanel-browser';

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

mixpanel.init('a5a0d04f9932c28e12c16f79b5821d80')
mixpanel.track('Page View');

function App(props) {
  const getDisplayComponent = type => {
    switch(type){
      case "login":{
        return <HomePage/>
      }
      case "AddListing":{
        return <HomePage/>
      }
      default:return null;
    }
  }
  return (
    <div className="App">
      {props.isOpenAuthModal? getDisplayComponent(props.currentModal):null}
      <Header/>
      <Router basename="/">
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/auth" component={HomePage}/>
          <Route exact path="/listing/add" component={AddListingPage} /> 
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isOpenAuthModal: state.isOpenAuthModal,
    authenticated: state.authenticated,
    currentModal: state.currentModal
  }
}

export default connect(mapStateToProps, {})(App);
