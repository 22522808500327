import React from 'react';
import { signInWithGoogle } from '../../util/common/authUtils';
import './header.css';

const Header = props => {
    return (
        <header className="header">
            <div className="brand"><a className="nav-link" href="/">GiveAway-Berlin</a></div>
            <ul className="menu">
                <li onClick={signInWithGoogle} className="link-button">Login</li>
                <li className="link-button"><a href="/listing/add" className="nav-link">Add</a></li>
            </ul>
        </header>
    )
}

export default Header;
