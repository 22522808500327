class HTTPApi{
    constructor(baseURI){
        if(this.baseURI===""){
            throw new Error("URL Empty");
        }
        this.baseURI = baseURI;
    }

    get(url,headers={}){
        headers['content-type']='application/json';
        return fetch(this.baseURI+url,{
            method:'GET',
            headers:headers
        });
    }

    post(url,data,headers={}){
        headers['content-type']='application/json'
        return fetch(this.baseURI+url,{
            method:'POST',
            headers:headers,
            body:JSON.stringify(data)

        });
    }

    put(url){

    }

    delete(url){

    }

    fileUpload(url,fileUrl,headers={}){
        return fetch(this.baseURI+url,{
            method:'POST',
            headers:headers,
            body:fileUrl
        })
    }
}

/* Example Usage

const caller = new HTTPApi("https://reqres.in/");
caller.get("/api/users?page=2")
.then(res=>console.log(res.json()))
.catch(err=>console.log(err)) 

*/

const Fetcher = new HTTPApi(process.env.REACT_APP_SERVICE_URL)

export default Fetcher;