import React from "react";
import "./listingForm.css";

const ListingForm = (props) => {
    return (
        <>
            <form className='listing-form'>
                <h2>Add a GiveAway item</h2>
                <div className='form-group'>
                    <label>Item Name</label>
                    <input type='text' name='name' />
                </div>
                <div className='form-group'>
                    <label>Item Type</label>
                    <select>
                        <option>Electronics</option>
                        <option>Books</option>
                        <option>Clothes</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label>Street</label>
                    <input type='text' name='street' />
                </div>
                <div className='form-group'>
                    <label>City</label>
                    <select>
                        <option>Berlin</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label>Postal</label>
                    <input type='text' name='postal' />
                </div>
                <div className='form-group'>
                    <label>Additional Info (optional)</label>
                    <input type='text' name='postal' />
                </div>
                <div className='form-group'>
                    <label>Upload Pictures</label>
                    <input type='file' name='postal' accept="image/png, image/jpeg" />
                </div>
                <div className='form-action'>
                    <button className="btn-cancel">Cancel</button>
                    <button className="btn-primary">Save</button>
                </div>
            </form>
        </>
    );
};

export default ListingForm;
