import React from 'react';
import ListingForm from '../../components/forms/listingForm';

const AddListingPage = props => {
    return (
        <ListingForm />
    )
}

export default AddListingPage;
