import caller from '../util/common/httpApi';

const TEST = 'TEST';
const LOADING = 'LOADING';
const LOADED = 'LOADED';
const LOGGED_IN = 'LOGGED_IN'
const LOG_OUT = 'LOG_OUT'
const axios = require('axios');
const LOGIN = 'LOGIN';
const Err = 'Err';
const notErr ='notErr';
const OPEN_AUTH_MODAL = Symbol("OPEN_AUTH_MODAL");
const FIREBASELOGIN = 'FIREBASELOGIN';

const test = (data)=>{
    return {
        type:TEST,
        data
    }
}

const openAuthModal = (isOpen,type) => async (dispatch, getState) => {
    dispatch({
      type: OPEN_AUTH_MODAL,
      payload: {open:isOpen,type:type}
    });
  };


const onError= (data)=>{
    return{
        type:Err,
        data
    }
}
const offError=()=>{
    return{
        type:notErr
    }
}

const loading=(data)=>{
    return{
        type:LOADING,
        data:true
    }
}

const authentication = (data)=>{
    return {
        type:LOGIN,
        data
    }
}

const firebaseAuth = (data) => {
    return {
        type: FIREBASELOGIN,
        data
    }
}

const logout = () => {
    localStorage.clear();
    return {
        type: LOG_OUT
    }
}

const getArguments = ()=>{
    return dispatch=>{
        return caller.get("/api/debates").then(res=>res.json())
    }
}

const login = (username,password)=>{
    return dispatch=> {
        return  axios(process.env.REACT_APP_SERVICE_URL+"/api/login",
                    { method:"POST",
                    headers:{'content-type':"application/json"},
                    data:JSON.stringify({"username":username,"password":password})
                        }
                        )
                    
}
}

const newUser = (name,email,password)=>{
    return dispatch=>{
        return axios(process.env.REACT_APP_SERVICE_URL+"/api/register",
                    {
                        method:"POST",
                        headers:{'content-type':'application/json'},
                        data:JSON.stringify({"username":name,"email":email,"password":password})
                    })
    }
}

const loaded = (data)=>{
    return{
        type:LOADED,
        data:false
    }
}

export {
    authentication,
    TEST,
    test,
    LOADED,
    LOADING,
    LOGGED_IN,
    LOG_OUT,
    loading,
    loaded,
    login,
    LOGIN,
    newUser,
    Err,
    onError,
    notErr,
    offError,
    OPEN_AUTH_MODAL,
    openAuthModal,
    FIREBASELOGIN,
    firebaseAuth,
    logout
}
